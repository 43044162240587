import React, { useEffect, useRef, useState } from "react";

import { FormWrapper, FormTitle } from "./styles";

const LeadForm = (props) => {
	const { title, portalId, formId, fontColor, parentSection, redirectionTo } =
		props;

	const formWrapperRef = useRef(null);
	const [hsformsLoaded, setHsformsLoaded] = useState(false);
	let randomFormId = Math.floor(Math.random() * 1000 + 1);

	useEffect(() => {
		if (typeof window !== "undefined") {
			if (window.hbspt) {
				const formDiv = document.createElement("div");
				formDiv.id = `hubspotForm-${formId}-${randomFormId}`;
				formDiv.className = "formContainer";
				formWrapperRef.current.appendChild(formDiv);
				window.hbspt.forms.create({
					portalId: portalId,
					formId: formId,
					target: `#hubspotForm-${formId}-${randomFormId}`,
				});
			} else {
				const invervalID = setInterval(() => {
					if (window.hbspt) {
						clearInterval(invervalID);
						setHsformsLoaded(true);
					}
				}, 100);
			}

			if (redirectionTo.enable && redirectionTo.link) {
				window.addEventListener("message", redirectTo);
			}
		}

		return () => {
			if (redirectionTo.enable && redirectionTo.link) {
				window.removeEventListener("message", redirectTo);
			}
		};
	}, [hsformsLoaded]);

	const redirectTo = (event) => {
		if (
			formId === event.data.id &&
			event.data.type === "hsFormCallback" &&
			event.data.eventName === "onFormSubmitted"
		) {
			if (redirectionTo.target === "_self") {
				window.location.assign(redirectionTo.link);
			} else {
				window.open(redirectionTo.link);
			}
		}
	};

	return (
		<FormWrapper
			className="lead-form"
			ref={formWrapperRef}
			parentSection={parentSection}
		>
			<FormTitle parentSection={parentSection} fontColor={fontColor}>
				{title}
			</FormTitle>
		</FormWrapper>
	);
};

LeadForm.defaultProps = {
	title: "",
	portalId: "",
	formId: "",
	fontColor: "#00000",
	parentSection: "right",
};

export default LeadForm;
